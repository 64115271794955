import request from "../utils/request";

export default {
    /**
     * Get all event list, only admin can get this
     * @param {String} eventName 
     * @returns {Promise}
     */
    getAllEventsList() {
      return request.get(`/admin/events/getEventListForDropDown`);
    },
}