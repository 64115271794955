import { takeLatest, all, put, call } from "redux-saga/effects";
import LoginService from '../services/LoginService';
import { setPageTitle } from "../utils/pageTitleHelper";

import AppDuc from "./duc";

export function* tryLoggingIn() {
  try{
      const {data} = yield call(() => LoginService.getFeatures());
      setPageTitle(data.pageTitle);
      yield put(AppDuc.creators.updateFavicon(data.favicon));
      yield put(AppDuc.creators.updateFeatures(data));
    } catch(e){
      yield put(AppDuc.creators.updateFeatures(null));
    }
}

export function updateFavicon(data) {
  const faviconEl = document.getElementById("favicon");
  if (!faviconEl.href) {
    faviconEl.href = data.data || "/dist/img/favicon.ico";
  }
}

export default function* AppSaga() {
  yield all([
    takeLatest(AppDuc.types.GET_FEATURES, tryLoggingIn),
    takeLatest(AppDuc.types.UPDATE_FAVICON, updateFavicon),
  ]);
}
