import request from "../utils/request";

export default {
    /**
     * Method to validate user credentials
     * @param {Object} data 
     * @param {String} url 
     * @returns {Promise}
     */
    validateUser(data ={}, url='') {
       return request.post(url, data);
    },

    /**
     * Method to fetch event specific data
     * @param {String} eventName 
     * @returns {Promise}
     */
    getMetaDetailsForEvent(eventName='', ) {
      return request.get(`/${eventName}/event/getMetaDetailsForEvent`);
    },

    /**
     * Resets password - which triggers otp to seset password
     * @param {Object} data 
     */
    sendForgotPasswordOtp(data){
      return request.post('/event/resetPassword', data);
    },

    /**
     * Change password by entering otp
     * @param {Object} data 
     */
    changePassword(data){
      return request.post('/event/changePassword', data);
    },

    /**
     * Submits otp for 2 factor authentication
     * @param {String} event 
     * @param {String} role 
     * @param {Object} data 
     */
    submitOTP(event, role, data){
      return request.post(`/${event}/${role}/otp/validate`, data);
    },

    /**
     * Resends otp during 2 factor authentication
     * @param {String} event 
     * @param {String} role 
     * @param {Object} data 
     */
    resendOTP(event, role, data){
      return request.post(`/${event}/${role}/otp/resend`, data);
    },

    getFeatures(){
      return request.get('/admin/features');
    }
}