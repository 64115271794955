import axios from "axios";

const instance = axios.create({
  headers: {
    'Cache-Tag': 'accred2',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
  },
  timeout: 10000,
});

instance.interceptors.response.use(function (response) {
  if(response?.data?.status === 401){
    console.log('please login');
    return new Promise((resolve, reject)=>{
      reject();
    })
  }
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 4xx cause this function to trigger
  // Do something with response error
  return new Promise((resolve, reject)=>{
    reject(error);
  })
});

export default instance;