import { combineReducers } from "redux";

import AppDuc from "../components/duc";
import EventListDuc from "../components/eventList/duc";

export default () =>
  combineReducers({
    [AppDuc.store]: AppDuc.reducer,
    [EventListDuc.store]: EventListDuc.reducer,
  });
  