import Duck from "extensible-duck";

export default new Duck({
  namespace: "app",
  store: "eventList",
  initialState: {
    eventList: [],
    selectedEvent: '',
  },
  types: [
    "GET_EVENT_LIST",
    "UPDATE_EVENT_LIST",
    "CHANGE_EVENT",
    "UPDATE_EVENT"
  ],
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.UPDATE_EVENT_LIST:
        return {
          ...state,
          eventList: action?.data || [],
        };
      case duck.types.UPDATE_EVENT:
        return {
          ...state,
          selectedEvent: action?.data || '',
        }
      default:
        return state;
    }
  },
  selectors: {
    root: (state) => state,
    eventList: (state) => state?.eventList?.eventList,
    selectedEvent: (state) => state?.eventList?.selectedEvent,
  },

  creators: (duck) => ({
    updateEventList: (data) => {
      return ({
        type: duck.types.UPDATE_EVENT_LIST,
        data,
      })
    },

    getEventList: (data) => ({
      type: duck.types.GET_EVENT_LIST,
      data,
    }),

    changeEvent: (data) => ({
      type: duck.types.CHANGE_EVENT,
      data,
    }),

    updateEvent: (data) => {
      return ({
        type: duck.types.UPDATE_EVENT,
        data,
      });
    }
  }),
});
