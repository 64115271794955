import { spawn, all, call } from "redux-saga/effects";

import AppSaga from '../components/saga';
import EventListSaga from "../components/eventList/saga";

// Watcher sagas for every module stack here
const sagas = [
    AppSaga,
    EventListSaga,
];

// spawn -- independent tasks (detached from parent)
export default function* RootSaga() {
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error("Saga failed", e);
          }
        }
      }),
    ),
  );
}
