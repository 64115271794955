import request from "../utils/request";
import storageHelper from '../utils/storageHelper';

export default {
  /**
   * Get settings of the selected event
   * @param {String} eventId 
   * @returns {Promise}
   */
  getEventSettings(eventId = storageHelper.getFromCookie('eventId')) {
    if (!eventId) {
      return new Promise((resolve) => {
        resolve(null);
      })
    }
    return request.get('/admin/events/settings?eventId=' + eventId);
  },

  updateEventSettings(data, config){
    return request.post('/admin/events/updateSettings', data, config);
  }
}