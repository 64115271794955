import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import AuthWrapper from "./AuthWrapper";
import EventSettings from "../../modules/eventSettings/EventSettingsComponent";
import Login from "../../modules/login/LoginComponent";
import ForgotPassword from "../../modules/forgotPassword/ForgotPasswordComponent";
import Otp from "../../modules/otp/OtpComponent";

function ProtectedRoutes(props) {
  const base = "/admin";
  return (
    <Router>
      <Switch>
        <Route exact strict path={`${base}/eventSetting`}>
          <AuthWrapper path={"/eventSetting"}>
            <EventSettings />
          </AuthWrapper>
        </Route>
        <Route exact strict path="/admin">
          <Login role="admin" />
        </Route>
        <Route exact strict path="/admin/forgotPassword">
          <ForgotPassword />
        </Route>
        <Route exact strict path="/admin/resetPassword">
          <ForgotPassword />
        </Route>
        <Route exact strict path="/:event/:role/forgotPassword">
          <ForgotPassword />
        </Route>
        <Route exact strict path="/:event/:role/resetPassword">
          <ForgotPassword />
        </Route>
        <Route exact strict path="/:event/:role/otp">
          <Otp />
        </Route>
        <Route exact strict path="/:event/:role">
          <Login />
        </Route>
        <Route path="/">
          <Redirect to="/admin" />
        </Route>
      </Switch>
    </Router>
  );
}

export default ProtectedRoutes;
