import React from 'react';
import ProtectedRoutes from './components/protectedRoutes';

function App() {
  return (
      <ProtectedRoutes />
  );
}

export default App;
