export default {
  login: {
    defaultBgImage: "/dist/img/accred-login-bg.jpg",
    bmsLogo: "/dist/img/bookmyshow-logo.png",
    defaultUserIcon: "/dist/img/login-page-user-icon.png",
  },

  roles: {
      "admin": "Super admin",
      "vendor-manager": "Vendor manager",
      "event-manager": "Event manager",
      "venue-manager": "Venue manager",
      "vendor": "Vendor",
  }
}