
/*
 * We should store data in cookie to support older version application
 * Reference: https://gist.github.com/nelsonJM/7813384
 */
export default {
    // In old appllication, this method name is getObjectProperty
    getFromCookie: function (fieldName) {
        let cookieName = encodeURIComponent(fieldName) + "=",
            cookieStart = document.cookie.indexOf(cookieName),
            cookieValue = null,
            cookieEnd;

        if (cookieStart > -1) {
            cookieEnd = document.cookie.indexOf(";", cookieStart);
            if (cookieEnd === -1) {
                cookieEnd = document.cookie.length;
            }
            cookieValue = decodeURIComponent(document.cookie.substring(cookieStart + cookieName.length, cookieEnd));
        }
        return cookieValue;
    },

    // In old appllication, this method name is setObjectProperty
    setToCooke: function (fieldName, value, path = "/") {
        let cookieText = encodeURIComponent(fieldName) + "=" + encodeURIComponent(value);
        if (path) {
            cookieText += "; path=" + path;
        }
        document.cookie = cookieText;
    }
}