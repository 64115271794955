import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Header from "../header/HeaderComponent";
import Sidebar from "../sidebar/SidebarComponent";
import AppDuc from "../duc";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./AuthWrapper.scss";

const AuthWrapper = (props) => {
  useEffect(() => {
    if (props.isLoggedIn == null) {
      props.getFeatures();
    }
  }, [props]);

  if (props.isLoggedIn === false) {
    window.location.href = "/";
  } else if (props.isLoggedIn) {
    return (
      <div className="content-wrapper">
        <Header />
        <Row>
          <Col md={2} className="nav-bar">
            <Sidebar path={props.path} />
          </Col>
          <Col>
            <div className="">
              <Container className={props.showMobileSidebar? 'container-fixed': ''}>{props.children}</Container>
            </div>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
};

const mapDispatchToProps = (dispatch) => ({
  getFeatures: () => dispatch(AppDuc.creators.tryLoggingIn()),
});

export default connect(
  createStructuredSelector({
    isLoggedIn: AppDuc?.selectors?.isLoggedIn,
    showMobileSidebar: AppDuc?.selectors?.showingMobileMenu,
  }),
  mapDispatchToProps
)(withRouter(AuthWrapper));
