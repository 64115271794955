import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button';
import EventList from '../../components/eventList/EventListComponent';
import EventService from '../../services/EventSettingsService';
import './EventSettingsComponent.scss';
import EventListDuc from '../../components/eventList/duc';


const EventSettings = (props) => {
    const [eventData, setEventData] = useState();
    const [validated, setValidated] = useState(false);
    const [bulkImportTC, setBulkImportTC] = useState('');
    const [pointsToRemember, setPointsToRemember] = useState('');
    const [userMessage, setUserMessage] = useState({isError: false, message: ''});
    const [isRequired, setRequiredAttribute] = useState(false);
    const aspectRatioSettingsRef = useRef(null);
    const [showPageTitleHelp, setShowPageTitleHelp] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(() => {
        EventService.getEventSettings(props?.selectedEvent).then((res) => {
            res && setEventData(res?.data?.data);
            if (aspectRatioSettingsRef.current) {
                handleRequiredAttribute(aspectRatioSettingsRef.current.checked);
            }
            setShowPageTitleHelp(false);
        });
    }, [props]);


    const handleSubmit = (e)=>{
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        if(form.checkValidity() === false){
            setUserMessage({
                isError: true, message: "Please fix the form errors!"
            })
        } else if (form.isImageAspectRatioMandatory.checked
                && (!(+form.imageHeight.value>0) || !(+form.imageWidth.value>0))) {
            setUserMessage({
                isError: true, message: "Please enter valid Photo Aspect Ratio Settings!"
            });
        } else {
            setUserMessage({isError: false, message: ""})
            const formData = {
                eventId: props.selectedEvent,
                nameCharacterLimit: +form.nameCharacterLimit.value,
                firstNameCharacterLimit: +form.firstNameCharacterLimit.value,
                lastNameCharacterLimit: +form.lastNameCharacterLimit.value,
                mobileNumberCharacterLimit: +form.mobileNumberCharacterLimit.value,
                userCompanyCharacterLimit: +form.userCompanyCharacterLimit.value,
                eventDayDutyNameCharacterLimit: +form.eventDayDutyNameCharacterLimit.value,
                barcodeCharacterLimit: +form.barcodeCharacterLimit.value,
                ageLimit: +form.ageLimit.value,
                playoffStatus: form.playoffStatus.value,
                otrApplicable: form.otrApplicable.checked,
                serialApplicable: form.serialApplicable.checked,
                barcodeApplicable: form.barcodeApplicable.checked,
                bibNumberApplicable: form.bibNumberApplicable.checked,
                emailCentralVendorMgr: form.emailCentralVendorMgr.checked,
                negativeCardStock: form.negativeCardStock.checked,
                confirmIdCardNo: form.confirmIdCardNo.checked,
                enableLateFormSubmission: form.enableLateFormSubmission.checked,
                imageDimensions: [],
                isImageAspectRatioMandatory: form.isImageAspectRatioMandatory.checked,
                is2faApplicable: form.is2faApplicable.checked,
                accessAreaSeparatorForPrint: form.accessAreaSeparatorForPrint.value,
                accessAreaPlaceholderForPrint: form.accessAreaPlaceholderForPrint.value,
                fileUploadSizeMaxLimit: +form.fileUploadSizeMaxLimit.value,
                showApplyingParty: form.showApplyingParty.checked,
                bulkImportConfirmTnC: bulkImportTC,
                pointsToRemember: pointsToRemember,
                pageTitle: form.pageTitle.value,
                copyrightText: form.copyrightText.value,
                isSetAccessAreaEnabled: form.isSetAccessAreaEnabled.checked
            }

            if (+form.imageHeight.value>0 && +form.imageWidth.value>0) {
                formData.imageDimensions = [
                    {
                        photo: {
                            height: +form.imageHeight.value,
                            width: +form.imageWidth.value,
                        }
                    }
                ];
            }

            setDisableSubmit(true);
            const config = {
                headers: {
                    'eventurl': eventData.eventUrl
                }
            }
            EventService.updateEventSettings(formData, config).then(res => {
                setUserMessage({isError: false, message: "Changes have been updated."});
                setDisableSubmit(false);
            }, err =>{
                setUserMessage({isError: true, message: err?.response?.data?.message});
                setDisableSubmit(false);
            })
        }
    }

    const handlePhotoAspectRatioSettings = () => {
        const isChecked = aspectRatioSettingsRef.current.checked;
        handleRequiredAttribute(isChecked);
    }

    const handleRequiredAttribute = (booleanValue) => {
        setRequiredAttribute(booleanValue);
        setValidated(true);
    }

    const handlePageTitleChange = (e) => {
        setShowPageTitleHelp(e.target.value !== eventData.pageTitle);
    }

    return (<Container fluid>
        <Col className="panel event-list">
        <h5>Event</h5>
            <EventList />
        </Col>

        <Form onSubmit={handleSubmit} noValidate validated={validated} key={props?.selectedEvent}>
            <Col className="panel">
                <h4>Accreditation Form</h4>
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={4} controlId="nameCharacterLimit">
                        <Form.Label>Set Character Limit For Complete Name*</Form.Label>
                        <Form.Control type="number" min="0" required placeholder="" key={eventData?.nameCharacterLimit} defaultValue={eventData?.nameCharacterLimit} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="firstNameCharacterLimit">
                        <Form.Label>Set Character Limit For First Name*</Form.Label>
                        <Form.Control type="number" placeholder="" min="0" required key={eventData?.firstNameCharacterLimit} defaultValue={eventData?.firstNameCharacterLimit} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="lastNameCharacterLimit">
                        <Form.Label>Set Character Limit For Last Name*</Form.Label>
                        <Form.Control type="number" placeholder="" min="0" required key={eventData?.lastNameCharacterLimit} defaultValue={eventData?.lastNameCharacterLimit} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={4} controlId="mobileNumberCharacterLimit">
                        <Form.Label>Set Character Limit For Mobile Number*</Form.Label>
                        <Form.Control type="number" placeholder="" min="0" required key={eventData?.mobileNumberCharacterLimit} defaultValue={eventData?.mobileNumberCharacterLimit} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="userCompanyCharacterLimit">
                        <Form.Label>Set Character Limit For User Company*</Form.Label>
                        <Form.Control type="number" placeholder="" min="0" required key={eventData?.userCompanyCharacterLimit} defaultValue={eventData?.userCompanyCharacterLimit} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="eventDayDutyNameCharacterLimit">
                        <Form.Label>Set Character Limit For Event Day Duty Name*</Form.Label>
                        <Form.Control type="text" placeholder="" min="0" required key={eventData?.eventDayDutyNameCharacterLimit} defaultValue={eventData?.eventDayDutyNameCharacterLimit} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={4} controlId="barcodeCharacterLimit">
                        <Form.Label>Barcode Length*</Form.Label>
                        <Form.Control type="number" placeholder="" min="0" required key={eventData?.barcodeCharacterLimit} defaultValue={eventData?.barcodeCharacterLimit} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="ageLimit">
                        <Form.Label>Min Age for Event*</Form.Label>
                        <Form.Control type="number" placeholder="" min="0" required key={eventData?.ageLimit} defaultValue={eventData?.ageLimit} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="playoffStatus">
                        <Form.Label>Set Playoff Status*</Form.Label>
                        <Form.Control as="select" key={eventData?.playoffStatus} defaultValue={eventData?.playoffStatus}>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Rejected">Rejected</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
                <Form.Row className="divider"> </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={4} controlId="otrApplicable">
                        <Form.Check type="checkbox" label="One Time Registeration Form" key={eventData?.otrApplicable} defaultChecked={eventData?.otrApplicable} className="text-dark"/>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="serialApplicable">
                        <Form.Check type="checkbox" label="Serial Number Applicable" key={eventData?.serialApplicable} defaultChecked={eventData?.serialApplicable} className="text-dark"/>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="barcodeApplicable">
                        <Form.Check type="checkbox" label="Barcode Manual Upload" key={eventData?.barcodeApplicable} defaultChecked={eventData?.barcodeApplicable} className="text-dark"/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={4} controlId="bibNumberApplicable">
                        <Form.Check type="checkbox" label="Bib Number Applicable" key={eventData?.bibNumberApplicable} defaultChecked={eventData?.bibNumberApplicable} className="text-dark"/>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="emailCentralVendorMgr">
                        <Form.Check type="checkbox" label="Enable Central Vendor Manager Email" key={eventData?.emailCentralVendorMgr} defaultChecked={eventData?.emailCentralVendorMgr} className="text-dark"/>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="negativeCardStock">
                        <Form.Check type="checkbox" label="Allow Negative Card Stock" key={eventData?.negativeCardStock} defaultChecked={eventData?.negativeCardStock} className="text-dark"/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={4} controlId="confirmIdCardNo">
                        <Form.Check type="checkbox" label="Confirm ID Card Number" key={eventData?.confirmIdCardNo} defaultChecked={eventData?.confirmIdCardNo} className="text-dark"/>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={4} controlId="enableLateFormSubmission">
                        <Form.Check type="checkbox" label="Enable Late Form Submission" key={eventData?.enableLateFormSubmission} defaultChecked={eventData?.enableLateFormSubmission} className="text-dark"/>
                    </Form.Group>
                </Form.Row>
            </Col>

            <Col className="panel">
                <h4>Photo Aspect Ratio Settings</h4>
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="imageHeight">
                        <Form.Label>Height{isRequired && (<>*</>)}</Form.Label>
                        <Form.Control type="number" placeholder="" min="0" required={isRequired} key={eventData?.imageDimensions[0]?.photo?.height} defaultValue={eventData?.imageDimensions[0]?.photo?.height}/>
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={6} controlId="imageWidth">
                        <Form.Label>Width{isRequired && (<>*</>)}</Form.Label>
                        <Form.Control type="text" placeholder="" min="0" required={isRequired} key={eventData?.imageDimensions[0]?.photo?.width} defaultValue={eventData?.imageDimensions[0]?.photo?.width}/>
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} xs={12} md={4} controlId="isImageAspectRatioMandatory" style={{flex: '0 0 100%', maxWidth: '100%'}}>
                        <Form.Check type="checkbox" label="Photo Aspect Ratio Mandatory (Disable Form Submission)" key={eventData?.isImageAspectRatioMandatory} defaultChecked={eventData?.isImageAspectRatioMandatory} className="text-dark" onClick={handlePhotoAspectRatioSettings} ref={aspectRatioSettingsRef}/>
                    </Form.Group>
                </Form.Row>
            </Col>

            <Col className="panel">
                <h4>Authentication Settings</h4>
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={4} controlId="is2faApplicable">
                        <Form.Check type="checkbox" label="2 Factor Authentication Applicable" key={eventData?.is2faApplicable} defaultChecked={eventData?.is2faApplicable} className="text-dark"/>
                    </Form.Group>
                </Form.Row>
            </Col>

            <Col className="panel">
                <h4>Print Module</h4>
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="accessAreaSeparatorForPrint">
                        <Form.Label>Set Access Area Separator Character For Print*</Form.Label>
                        <Form.Control type="text" placeholder="" required key={eventData?.accessAreaSeparatorForPrint} defaultValue={eventData?.accessAreaSeparatorForPrint} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} controlId="accessAreaPlaceholderForPrint">
                        <Form.Label>Set Access Area Placeholder For Print(If Empty)</Form.Label>
                        <Form.Control type="text" placeholder="#" key={eventData?.accessAreaPlaceholderForPrint} defaultValue={eventData?.accessAreaPlaceholderForPrint} />
                    </Form.Group>
                </Form.Row>
            </Col>

            <Col className="panel">
                <h4>Bulk Import</h4>
                <Form.Row>
                    <Form.Group as={Col}  xs={12} md={6} controlId="fileUploadSizeMaxLimit">
                        <Form.Label>Set Maximum Limit for File Upload Size(MB)*</Form.Label>
                        <Form.Control type="number" placeholder="" min="0" required key={eventData?.fileUploadSizeMaxLimit} defaultValue={eventData?.fileUploadSizeMaxLimit} />
                        <Form.Control.Feedback type="invalid">
                            This field is required.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={4} controlId="showApplyingParty" className="show-applying-party">
                        <Form.Check type="checkbox" label="Show Applying Party" key={eventData?.showApplyingParty} defaultChecked={eventData?.showApplyingParty} className="text-dark"/>
                    </Form.Group>
                </Form.Row>

                <section>
                    <Form.Row>
                        <h6>Bulk Import Terms & Conditions - Confirm</h6>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Row} controlId="bulkImportConfirmTnC" className='ck-editor-group'>
                            <CKEditor
                            sm={12}
                                config={
                                    {
                                    link: {
                                        addTargetToExternalLinks: true
                                        }
                                    }
                                }
                                editor={ClassicEditor}
                                data={eventData?.bulkImportConfirmTnC || ''}
                                onInit={editor => {
                                    setBulkImportTC(eventData?.bulkImportConfirmTnC);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setBulkImportTC(data);
                                    }}
                            />
                        </Form.Group>
                    </Form.Row>
                </section>

                <section>
                    <Form.Row>
                        <h6>Points To Remember</h6>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Row} controlId="pointsToRemember" className='ck-editor-group'>
                            <CKEditor

                                sm={12}
                                config={
                                    {
                                    link: {
                                        addTargetToExternalLinks: true
                                        }
                                    }
                                }
                                editor={ClassicEditor}
                                data={eventData?.pointsToRemember || ''}
                                onInit={editor => {
                                    setPointsToRemember(eventData?.pointsToRemember);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setPointsToRemember(data);
                                    }}
                            />
                        </Form.Group>
                    </Form.Row>
                </section>
            </Col>
            <Col className="panel">
                <h4>Page Settings</h4>
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="pageTitle">
                        <Form.Label>Page Title</Form.Label>
                        <Form.Control type="text" placeholder="" key={eventData?.pageTitle} defaultValue={eventData?.pageTitle} onChange={handlePageTitleChange} />
                        {showPageTitleHelp &&
                            <Form.Text className="text-muted">
                                Updated text will take effect once the user(s) log out and log in again.
                            </Form.Text>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={12} controlId="copyrightText">
                        <Form.Label>Copyright Text</Form.Label>
                        <Form.Control type="text" placeholder="" key={eventData?.copyrightText} defaultValue={eventData?.copyrightText} />
                    </Form.Group>
                </Form.Row>
            </Col>
            <Col className="panel">
                <h4>Approvals</h4>
                <Form.Row>
                    <Form.Group as={Col} xs={12} md={6} controlId="isSetAccessAreaEnabled">
                        <Form.Check type="checkbox" label="Set Access Area" key={eventData?.isSetAccessAreaEnabled} defaultChecked={eventData?.isSetAccessAreaEnabled} className="text-dark"/>
                    </Form.Group>
                </Form.Row>
            </Col>
            {/* Show success or error message in the alert */}
            {userMessage?.message && <Form.Row className="d-flex justify-content-center">
            <Alert variant={userMessage?.isError? "danger": "success"}>
                {userMessage?.message}
            </Alert>
            </Form.Row>}
            <Form.Row className="submit-btn">
                <Button variant="primary" type="submit" size="lg" disabled={disableSubmit}>
                    Submit
                </Button>
            </Form.Row>

        </Form>
    </Container>)
}

const mapDispatchToProps = () => ({});

export default connect(
    createStructuredSelector({
        selectedEvent: EventListDuc?.selectors?.selectedEvent,
    }),
    mapDispatchToProps,
)(withRouter(EventSettings));