import { takeLatest, all, put, call } from "redux-saga/effects";
import CommonService from '../../services/CommonService';
import StorageHelper from '../../utils/storageHelper';

import EventListDuc from "./duc";

export function* updateEventList() {
    try {
        const { data } = yield call(() => CommonService.getAllEventsList());
        yield put(EventListDuc.creators.updateEventList(data?.data?.data));
    } catch (e) {
        yield put(EventListDuc.creators.updateEventList([]));
    }
}

export function* changeEvent(params) {
    try {
        const eventId = params.data || StorageHelper.getFromCookie('eventId');
        StorageHelper.setToCooke('eventId', eventId)
        yield put(EventListDuc.creators.updateEvent(eventId));
    } catch (e) {
    }
}

export default function* LoginSaga() {
    yield all([
        takeLatest(EventListDuc.types.GET_EVENT_LIST, updateEventList),
        takeLatest(EventListDuc.types.CHANGE_EVENT, changeEvent),
    ]);
}
