export default {
  event: {
    type: "img",
    src: "/dist/img/calendar-icon-active.png",
  },
  report: {
    type: "img",
    src: "/dist/img/reports.png",
  },
  archive: {
    type: "img",
    src: "/dist/img/archived.png",
  },
  user: {
    type: "img",
    src: "/dist/img/user-mgnt.png",
  },
  department: {
    type: "fa",
    src: "fa-user-secret",
  },
  venue: {
    type: "img",
    src: "/dist/img/venue_creation.png",
  },
  modification: {
    type: "img",
    src: "/dist/img/modification.png",
  },
  settings: {
    type: "fa",
    src: "fa-gears",
  },
  upload: {
    type: "fa",
    src: "fa-cloud-upload",
  },
  alert: {
    type: "fa",
    src: "fa-bullhorn",
  },
  question: {
    type: "fa",
    src: "fa-question-circle",
  },
};
