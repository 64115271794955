import React, { useState, useEffect } from "react";

import SubRows from "./subRows";
import icons from "./icons";

function Row(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isActiveRoute = props.activeRoute === props.row.routePath;
  const expandMenus = (event) => {
    if (props.row.routePath) {
      props.setActiveRoute(props.row.routePath);
    } else {
      setIsExpanded(!isExpanded);
    }
    event.stopPropagation();
  };
  useEffect(() => {
    if (props.row.subMenu && props.row.subMenu.length) {
      props.row.subMenu.forEach((menu) => {
        if (menu.routePath === props.activeRoute) {
          !isExpanded && setIsExpanded(true);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeRoute, props.row.subMenu]);

  // getting icons dynamically from config
  const getIcon = (iconType) => {
    if (icons[iconType]) {
      if (icons[iconType].type === "img")
        return <img src={icons[iconType].src} alt="" />;
      else return <i className={"fa " + icons[iconType].src}></i>;
    } else {
      return <i className="fa fa-list-alt"></i>;
    }
  };

  return (
    <li
      onClick={expandMenus}
      className={"treeview " + (isActiveRoute ? "active" : "")}
    >
      {/* eslint-disable-next-line */}
      <a className={isExpanded ? "expanded-children " : ""}>
        {props.row.iconType && getIcon(props.row.iconType)}{" "}
        <span>{props.row.label}</span>
        {props.row.subMenu && (
          <i
            className={
              "fa pull-right " + (isExpanded ? "fa-angle-up" : "fa-angle-left")
            }
          ></i>
        )}
      </a>
      {props.row.subMenu && props.row.subMenu.length && (
        <SubRows
          subMenu={props.row.subMenu}
          isExpanded={isExpanded}
          activeRoute={props.activeRoute}
          setActiveRoute={props.setActiveRoute}
        />
      )}
    </li>
  );
}

export default Row;
