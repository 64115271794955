import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useParams } from "react-router-dom";

import AppDuc from "../../components/duc";
import Row from "./utils/row";

import "./SidebarComponent.scss";

function Sidebar(props) {
  const [activeRoute, setActiveRoute] = useState(props.path);
  const data = props.features;
  let { event, role } = useParams();
  const baseURL =
    props?.features?.roleType === "Super admin"
      ? "/admin"
      : `/${event}/${role}/`;

  useEffect(() => {
    if (activeRoute !== props.path) {
      window.location.href = baseURL + activeRoute;
    }
  }, [activeRoute, baseURL, props.path]);

  return (
    <aside className={"main-sidebar sidebar-dark-primary elevation-4 " + (props.showMobileSidebar?'main-sidebar-show': 'main-sidebar-hide')}>
      <section className="sidebar">
        <ul className="sidebar-menu">
          {data &&
            data.featureList.map((menu) => {
              return (
                <Row
                  row={menu}
                  key={menu.label}
                  activeRoute={activeRoute}
                  setActiveRoute={setActiveRoute}
                />
              );
            })}
        </ul>
      </section>
      {props.showMobileSidebar && <button className="closeMenu" onClick={props.toggleMobileSidebar}><i className="fa fa-times" aria-hidden="true"></i></button>}
    </aside>
  );
}

const mapDispatchToProps = (dispatch) => ({
  toggleMobileSidebar: () => dispatch(AppDuc.creators.toggleMobileSidebar()),
});

export default connect(
  createStructuredSelector({
    features: AppDuc?.selectors?.features,
    showMobileSidebar: AppDuc?.selectors?.showingMobileMenu,
  }),
  mapDispatchToProps
)(withRouter(Sidebar));
