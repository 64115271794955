import React from "react";

import Row from "./row";

function SubRows(props) {
  return (
    <ul className={"treeview-menu" + (props.isExpanded ? " expanded" : "")}>
      {props.subMenu.map((menu) => {
        return (
          <Row
            row={menu}
            key={menu.label}
            activeRoute={props.activeRoute}
            setActiveRoute={props.setActiveRoute}
          />
        );
      })}
    </ul>
  );
}

export default SubRows;
