import Duck from "extensible-duck";

export default new Duck({   
  namespace: "app",
  store: "app",
  initialState: {
    isLoggedIn: null,
    features: null,
    showMobileSidebar: false,
  },
  types: [
    "GET_FEATURES",
    "UPDATE_FEATURES",
    "TOGGLE_SIDEBAR",
    "UPDATE_FAVICON",
  ],
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.UPDATE_FEATURES:
        return {
          ...state,
          isLoggedIn: action?.data? true : false,
          features: action?.data,
        };
      case duck.types.TOGGLE_SIDEBAR:
        return {
          ...state,
          showMobileSidebar: !state.showMobileSidebar,
        };
      case duck.types.UPDATE_FAVICON:
        return {
          ...state,
          favicon: action?.data || "/dist/img/favicon.ico",
        }
      default:
        return state;
    }
  },
  selectors: {
    root: (state) => state,
    isLoggedIn: (state) => state?.app?.isLoggedIn,
    features: (state) => state?.app?.features,
    showingMobileMenu: (state) => state?.app?.showMobileSidebar,
    favicon: (state) => state?.app?.favicon,
  },

  creators: (duck) => ({
    updateFeatures: (data) => {
      return ({
        type: duck.types.UPDATE_FEATURES,
        data,
      })
    },

    tryLoggingIn: (data) => ({
      type: duck.types.GET_FEATURES,
      data,
    }),

    toggleMobileSidebar: (data)=>({
      type: duck.types.TOGGLE_SIDEBAR,
      data,
    }),

    updateFavicon: (data) => ({
      type: duck.types.UPDATE_FAVICON,
      data,
    })
  }),
});
