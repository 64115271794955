import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import NavDropdown from "react-bootstrap/NavDropdown";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useParams } from "react-router-dom";

import AppDuc from "../../components/duc";
import "./HeaderComponent.scss";
import constants from '../../config/constants';

function Header(props) {
  const userInfo = {
    name: props?.features?.username,
    role: props?.features?.roleType,
    _id: "",
    photo: props?.features?.profilePic,
    role_title:
      props?.features?.roleType === constants.roles.admin
        ? "Administrator"
        : props?.features?.roleType,
  };
  let { event, role } = useParams();

  const baseURL =
    props?.features?.roleType === constants.roles.admin
      ? "/admin/"
      : `/${event}/${role}/`;

  return (
    <>
    <Navbar bg="light" expand="lg" fixed="top">
      <Navbar.Brand className="logo">
        <img
          src="/custom/img/bookmyshow-logo.png"
          alt=""
          className="img-responsive headerLogo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto d-none d-sm-block">
          <Col className="role">{userInfo.role_title}</Col>
        </Nav>

        <Nav className="mr-auto d-none d-sm-block pull-right">
          <a
            href="/admin/contentMgmt"
            title="Content Mgmt"
            className="pull-right contnmgmt"
          >
            <img src="/dist/img/content-mgt-img.png" alt="Content Mgmt" />
          </a>
        </Nav>
        <NavDropdown
          title={userMenu(userInfo)}
          id="user-nav-dropdown"
          className="d-none d-sm-block"
        >
          <NavDropdown.Item href={baseURL + "changePhoto"}>
            Change Photo
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href={baseURL + "changePassword"}>
            Change Password
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            href={baseURL + "logout?userId=" + props?.features?.userId}
          >
            Sign Out
          </NavDropdown.Item>
        </NavDropdown>

        <Nav className="d-none d-sm-block d-md-none d-block d-sm-none">
          <Nav.Link href={baseURL + "changePhoto"}>Change Photo</Nav.Link>
        </Nav>
        <Nav className="d-none d-sm-block d-md-none d-block d-sm-none">
          <Nav.Link href={baseURL + "changePassword"}>Change Password</Nav.Link>
        </Nav>
        <Nav className="d-none d-sm-block d-md-none d-block d-sm-none">
          <Nav.Link href={baseURL + "logout?userId=" + props?.features?.userId}>
            Sign Out
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <div className={"mobile-menu " + (!props.showMobileSidebar?'main-sidebar-show': 'main-sidebar-hide')} onClick={props.toggleMobileSidebar}>
    <i className="fa fa-bars" aria-hidden="true"></i> Menu
    </div>
    </>
  );
}

const userMenu = function (userInfo) {
  return (
    <div className="user-role-wrapper">
      <div className="user-role">
        <img
          src={userInfo.photo}
          className="rounded-circle profile-img"
          alt=""
        />
        <div className="user-and-role">
          <span className="username">{userInfo.name}</span>
          <small className="rolename">{userInfo.role}</small>
        </div>
      </div>
      <i className="fa fa-angle-down profile-dropdown"></i>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleMobileSidebar: () => dispatch(AppDuc.creators.toggleMobileSidebar()),
});

export default connect(
  createStructuredSelector({
    features: AppDuc?.selectors?.features,
    showMobileSidebar: AppDuc?.selectors?.showingMobileMenu,
  }),
  mapDispatchToProps
)(withRouter(Header));
