import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import {
  useParams,
  useLocation
} from "react-router-dom";

import LoginService from '../../services/LoginService';
import constants from '../../config/constants';

import './OtpComponent.scss';
import { setPageTitle } from '../../utils/pageTitleHelper';

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import AppDuc from "../../components/duc";

function Otp(props) {
  const [otpFormHasError, setOtpFormHasError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [eventConfig, setEventConfig] = useState({});
  let { event, role } = useParams();
  const location = useLocation();
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (event) {
      LoginService.getMetaDetailsForEvent(event).then((response) => {
        if (response && !Object.keys(eventConfig).length && response.data) {
          if (!response.data.data.eventBackground) {
            response.data.data.eventBackground = constants.login.defaultBgImage;
          }
          setEventConfig(response.data.data);
        }
        setPageTitle(response.data?.data?.pageTitle);
        props.updateFavicon(response.data?.data?.favicon);
      }).catch(() => {
        window.location = '/404.html';
      })
    } else {
      setEventConfig({
        eventBackground: constants.login.defaultBgImage
      });
      setPageTitle();
      props.updateFavicon();
    }
  }, []);

  const getLandingPageURL = (loginData) => {
    if (event) {
      let landingPage = `/${event}/${role}/dashboard`;
      if (loginData.data.customData === 'print' && role === 'vendor-manager') {
        landingPage = `/${event}/${role}/printCardHolder`;
      } else if (loginData.data.is2faApplicable) {
        landingPage = `/${event}/${role}/${loginData.data.otpRedirectionUrl}`;
      }
      return landingPage;
    } else {
      return '/admin/events';
    }
  }

  const handleOtpSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    const otp = form.otp.value;
    if (form.checkValidity() === true) {
      setOtpFormHasError(false);
      const eventData = JSON.stringify({ eventUrl: event, roletype: role });
      const postData = {
        eventId: eventConfig._id,
        totp: otp,
        roleType: constants.roles[props.role || role],
        eventData
      };

      setDisableSubmit(true);
      LoginService.submitOTP(event, role, postData).then((response) => {
        window.location = getLandingPageURL(response.data);
      }).catch((err) => {
        setErrorMessage(err?.response?.data?.message || '');
      }).finally(() => {
        setDisableSubmit(false);
      })

    } else {
      setOtpFormHasError(true);
    }
  }

  const handleResendOtp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const otpRequestDate = new Date().toISOString();
    const postData = { otpRequestDate };
    LoginService.resendOTP(event, role, postData).then((res) => {
        setErrorMessage('');
        setSuccessMessage('You will receive an OTP on your registered email shortly!');
    }).catch(function (err) {
      setSuccessMessage('');
      setErrorMessage(err?.response?.data?.message?.message || '');
    })
  }

  const bodyBgImg = {
    backgroundImage: `url("${eventConfig?.eventBackground}")`
  };

  const handleBackToLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();

    window.location.href = `/${event}/${role}`
  }

  return (
    <div className="wrapper" style={bodyBgImg}>
      <div className="id-card">
        <div className="login-box">
        </div>
        <div className="login-box-body">
          <div className="bms-admin bms-roleType">OTP</div>
          {errorMessage.length?  <p className="login-box-msg">{errorMessage}</p> : null}
          {(!errorMessage.length && successMessage.length)? <p className="login-box-msg green">{successMessage} </p>: null }
          {(!errorMessage.length && !successMessage.length && location.state?.otpMsg) && <p className="login-box-msg">{location.state.otpMsg}</p>}
          <Form className="loginForm" noValidate validated={otpFormHasError} onSubmit={handleOtpSubmit}>
            <Form.Group controlId="otp">
              <Form.Control type="text" required placeholder="OTP" />
              <Form.Control.Feedback type="invalid">
                Please enter OTP
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="btn btn-block loginSubmit" type="submit" disabled={disableSubmit}>
              SUBMIT
            </Button>
            <Row className="text-center">
              <div className="forgotpw footer-links" id="forgotLink"><span className="redirection-link" onClick={handleBackToLogin}>Back to Login?</span> <span className="redirection-link" onClick={handleResendOtp}>Resend OTP</span></div>
            </Row>
          </Form>
        </div>
      </div>
      {eventConfig?.copyrightText && <p className="copyright-text">{eventConfig.copyrightText}</p>}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateFavicon: (data) => dispatch(AppDuc.creators.updateFavicon(data)),
});

export default connect(
  createStructuredSelector({
    favicon: AppDuc?.selectors?.favicon,
  }),
  mapDispatchToProps
)(Otp);
