import { createStore, applyMiddleware, compose } from "redux";
import createSagaMidleware from "redux-saga";

import combineReducers from "./reducer";
import RootSaga from "./rootSaga";

export default function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMidleware();

    const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = createStore(combineReducers(), initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(RootSaga);

  return store;
}