import React, { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { useParams, useHistory } from "react-router-dom";

import LoginService from "../../services/LoginService";
import constants from "../../config/constants";
import LoginUtils from "./util";

import "./LoginComponent.scss";
import { setPageTitle } from "../../utils/pageTitleHelper";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import AppDuc from "../../components/duc";

function LoginComponent(props) {
  const [validated, setValidated] = useState(false);
  const [invalidCredentialsMessage, setInvalidCredentialsMessage] = useState(
    false
  );
  const [eventConfig, setEventConfig] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  let { event, role } = useParams();
  let history = useHistory();
  
  useEffect(() => {
    if (event) {
      LoginService.getMetaDetailsForEvent(event)
        .then((response) => {
          if (!Object.keys(eventConfig).length && response.data) {
            if (!response.data.data.eventLogo) {
              response.data.data.eventLogo = constants.login.bmsLogo;
            }
            if (!response.data.data.eventBackground) {
              response.data.data.eventBackground = constants.login.defaultBgImage;
            }
            setEventConfig(response.data.data);
          }
          setPageTitle(response.data?.data?.pageTitle);
          props.updateFavicon(response.data?.data?.favicon);
        })
        .catch(() => {
          window.location = "/404.html";
        });
    } else {
      setEventConfig({
        eventLogo: constants.login.bmsLogo,
        eventBackground: constants.login.defaultBgImage
      });
      setPageTitle();
      props.updateFavicon();
    }
  }, []);

  const getRequestObj = (form) => {
    const obj = {
      username: form.userName.value,
      password: form.userPassword.value,
      eventId: event ? eventConfig._id : "",
      userType: constants.roles[props.role || role],
    };
    if (event) {
      obj.eventName = event;
    }
    return obj;
  };

  const getLandingPageURL = (loginData) => {
    if (event) {
      let landingPage = `/${event}/${role}/dashboard`;
      if (loginData.data.customData === "print" && role === "vendor-manager") {
        landingPage = `/${event}/${role}/printCardHolder`;
      } else if (loginData.data.is2faApplicable) {
        landingPage = `/${event}/${role}/${loginData.data.otpRedirectionUrl}`;
      }
      return landingPage;
    } else {
      return "/admin/events";
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) {
      setDisableSubmit(true);
      LoginService.validateUser(
        getRequestObj(form),
        LoginUtils.getLoginURL(event)
      )
        .then((response) => {
          const url = getLandingPageURL(response.data);
          if (response.data?.data?.otpRedirectionUrl) {
            history.push(url, {otpMsg: response.data?.data?.otpMsg});
          } else {
            window.location = url;
          }
        })
        .catch(() => {
          setInvalidCredentialsMessage(true);
        }).finally(() => {
          setDisableSubmit(false);
        });
    }
    setValidated(true);
  };

  const bodyBgImg = {
    backgroundImage: `url("${eventConfig.eventBackground}")`,
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = window.location.href + "/forgotPassword";
  };
  return (
    <div className="wrapper" style={bodyBgImg}>
      {props.isLoggedIn}
      <div className="id-card">
        <div className="login-box"></div>
        <div className="login-box-body">
          <div className="logintitle">Accreditation</div>
          <div className="event-logo">
            <img
              src={eventConfig.eventLogo}
              title=""
              alt=""
              id="eventLogo"
            />
          </div>
          {invalidCredentialsMessage && (
            <p className="login-box-msg">Invalid Credentials</p>
          )}
          <div className="loginusericon">
            <img src="/dist/img/login-page-user-icon.png" title="" alt="" />
          </div>
          <p className="bms-admin bms-roleType">
            {role ? constants.roles[role] : "BMS Admin"}
          </p>
          <Form
            className="loginForm"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group controlId="userName">
              <Form.Control type="text" required placeholder="User Name" />
              <Form.Control.Feedback type="invalid">
                Please enter username
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="userPassword">
              <Form.Control type="password" required placeholder="Password" />
              <Form.Control.Feedback type="invalid">
                Please enter password
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="btn btn-block loginSubmit" type="submit" disabled={disableSubmit}>
              SIGN IN
            </Button>
            <Row className="text-center">
              <p className="forgotpw" id="forgotLink">
                <a href="/" onClick={handleForgotPassword}>
                  Forgot Password?
                </a>
              </p>
            </Row>
          </Form>
        </div>
      </div>
      {eventConfig?.copyrightText && <p className="copyright-text">{eventConfig.copyrightText}</p>}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateFavicon: (data) => dispatch(AppDuc.creators.updateFavicon(data)),
});

export default connect(
  createStructuredSelector({
    favicon: AppDuc?.selectors?.favicon,
  }),
  mapDispatchToProps
)(LoginComponent);
