import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Dropdown from "react-bootstrap/Dropdown";
import FormControl from "react-bootstrap/FormControl";
import EventListDuc from "./duc";
import "./EventListComponent.scss";

const EventList = (props) => {
  const [value, setValue] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");

  useEffect(() => {
    if (!props.eventList || !props.eventList.length) {
      props.getEventList();
    }
    if (!props.selectedEvent) {
      props.changeEvent();
    }

    if (props?.selectedEvent && !selectedEvent) {
      const selectedEvtObj = props.eventList?.filter(
        (evt) => evt._id === props?.selectedEvent
      )[0];
      setSelectedEvent(selectedEvtObj?.eventName);
    }
  }, [props, selectedEvent]);

  const eventChangeHandler = (e) => {
    setSelectedEvent(e.target.innerText);
    props.changeEvent(e.target.getAttribute("value"));
  };

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      as="input"
      className="form-control event-search"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children || "--"}
      <span>&#x25bc;</span>
    </span>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="suggetion-search"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="suggetion-list">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-custom-components"
      >
        {selectedEvent && selectedEvent.replace('&amp;', '&')}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {props.eventList &&
          props.eventList.map((event) => {
            return (
              <Dropdown.Item
                onClick={eventChangeHandler}
                eventKey={event._id}
                key={event._id}
                value={event._id}
              >
                {event.eventName.replace('&amp;', '&')}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getEventList: () => dispatch(EventListDuc.creators.getEventList()),
  changeEvent: (data) => dispatch(EventListDuc.creators.changeEvent(data)),
});

export default connect(
  createStructuredSelector({
    eventList: EventListDuc?.selectors?.eventList,
    selectedEvent: EventListDuc?.selectors?.selectedEvent,
  }),
  mapDispatchToProps
)(withRouter(EventList));
