import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";

import LoginService from "../../services/LoginService";
import constants from "../../config/constants";

import "./ForgotPasswordComponent.scss";
import { setPageTitle } from "../../utils/pageTitleHelper";

import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import AppDuc from "../../components/duc";

function ForgotPassword(props) {
  const [otpFormHasError, setOtpFormHasError] = useState(false);
  const [passwordFormHasError, setPasswordFormHasError] = useState(false);
  const [errors, setErrors] = useState("");
  const [otpValidated, setOtpValidated] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userObj, setUserObj] = useState({});
  const [showOnlyBackToLogin, setShowOnlyBackToLogin] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [eventConfig, setEventConfig] = useState({});
  let { event, role } = useParams();

  useEffect(() => {
    if (event) {
      LoginService.getMetaDetailsForEvent(event).then(response => {
        if (!Object.keys(eventConfig).length && response.data) {
          if (!response.data.data.eventBackground) {
            response.data.data.eventBackground = constants.login.defaultBgImage;
          }
          setEventConfig(response.data.data);
        }
        setPageTitle(response.data?.data?.pageTitle);
        props.updateFavicon(response.data?.data?.favicon);
      });
    } else {
      setEventConfig({
        eventBackground: constants.login.defaultBgImage
      });
      setPageTitle();
      props.updateFavicon();
    }
  }, []);

  const checkPasswordStrength = password => {
    let errMsg = "";
    const letterRegex = /[a-zA-Z]/;
    const numberRegex = /[0-9]/;
    // eslint-disable-next-line no-useless-escape
    const specialCharacterRegex = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;
    const isAlphanumeric = numberRegex.test(password) && letterRegex.test(password);
    const hasSpecialChar = specialCharacterRegex.test(password);
    const isLowerCaseRegex = /[a-z]/;
    const isUpperCaseRegex = /[A-Z]/;
    const isLowerCase = isLowerCaseRegex.test(password);
    const isUpperCase = isUpperCaseRegex.test(password);

    if (!password) {
      return { isIncorrectPassword: false, errMsg: errMsg };
    }

    if (password.length < 10) {
      errMsg = "Password should be of at least 10 characters";
      return { isIncorrectPassword: true, errMsg: errMsg };
    }
    if (!isAlphanumeric) {
      errMsg = "Password should contain digits and alphabets";
      return { isIncorrectPassword: true, errMsg: errMsg };
    }
    if (!hasSpecialChar) {
      errMsg = "Password should at least contain a special character";
      return { isIncorrectPassword: true, errMsg: errMsg };
    }
    if (!isLowerCase) {
      errMsg = "Password should at least contain a lowercase character";
      return { isIncorrectPassword: true, errMsg: errMsg };
    }
    if (!isUpperCase) {
      errMsg = "Password should at least contain a uppercase character";
      return { isIncorrectPassword: true, errMsg: errMsg };
    }

    return { isIncorrectPassword: false, errMsg: errMsg };
  };

  const getRequestObj = form => {
    const Obj = {
      username: form.userName.value,
      otpRequestDate: new Date().toISOString(),
      eventId: event ? eventConfig._id : "",
      roleType: constants.roles[props.role || role]
    };
    setUserObj(Obj);
    return Obj;
  };

  const handleOtpSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      setDisableSubmit(true);
      LoginService.sendForgotPasswordOtp(getRequestObj(form))
        .then(res => {
          if (res.status === 200) {
            setErrorMessage("");
            setSuccessMessage(res.data.message);
            setOtpValidated(true);
          }
        })
        .catch(err => {
          setErrorMessage(err?.response?.data?.message || "");
        })
        .finally(() => {
          setDisableSubmit(false);
        });
    }
    setOtpFormHasError(false);
  };

  const handleResendOtp = event => {
    event.preventDefault();
    event.stopPropagation();
    LoginService.sendForgotPasswordOtp(userObj)
      .then(res => {
        if (res.status === 200) {
          setErrorMessage("");
          setSuccessMessage(res.data.message);
        }
      })
      .catch(function (err) {
        setErrorMessage(err.response.data.message);
      });
  };

  const handlePasswordReset = e => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    const otp = form.otp.value;
    const newPassword = form.newPassword.value;
    const repeatNewPassword = form.repeatNewPassword.value;
    if (!otp || !newPassword || !repeatNewPassword) {
      setPasswordFormHasError(true);
    }
    if (newPassword !== repeatNewPassword) {
      setErrors("New password and confirm password must be same");
      setPasswordFormHasError(true);
    } else {
      const validatePassword = checkPasswordStrength(newPassword);
      if (validatePassword.isIncorrectPassword) {
        setErrors(validatePassword.errMsg);
      } else {
        setErrors("");
        if (form.checkValidity() === true) {
          const obj = {
            username: userObj.username,
            eventId: event ? eventConfig._id : "",
            otp: otp,
            newPassword: newPassword
          };
          setDisableSubmit(true);
          LoginService.changePassword(obj)
            .then(res => {
              if (res.data.status === 200) {
                setErrorMessage("");
                setSuccessMessage("Password Changed Succesfully. You can login using the new password.");
                setShowOnlyBackToLogin(true);
                form.otp.value = "";
                form.newPassword.value = "";
                form.repeatNewPassword.value = "";
              } else {
                setErrorMessage(res?.data?.message);
              }
            })
            .catch(err => {
              setErrorMessage(err?.response?.data?.message);
            })
            .finally(() => {
              setDisableSubmit(false);
            });
        }
      }
    }
  };

  const bodyBgImg = {
    backgroundImage: `url("${eventConfig.eventBackground}")`
  };

  const handleBackToLogin = e => {
    e.preventDefault();
    e.stopPropagation();
    if (event) {
      window.location.href = `/${event}/${role}/`;
    } else {
      window.location.href = "/";
    }
  };

  return (
    <div className="forgot-password wrapper" style={bodyBgImg}>
      <div className="id-card">
        <div className="login-box"></div>
        <div className="login-box-body">
          {!otpValidated && <div className="bms-admin bms-roleType">Insert Username</div>}
          {!otpValidated && (
            <Form className="loginForm" noValidate validated={otpFormHasError} onSubmit={handleOtpSubmit}>
              <Form.Group controlId="userName">
                <Form.Control type="text" required placeholder="Username" />
                <Form.Control.Feedback type="invalid">Please enter username</Form.Control.Feedback>
              </Form.Group>

              {!otpValidated && errorMessage.length > 0 ? <p className="login-box-msg" dangerouslySetInnerHTML={{ __html: errorMessage }} /> : null}

              <Button className="btn btn-block loginSubmit" type="submit" disabled={disableSubmit}>
                Send OTP
              </Button>
              <Row className="text-center">
                <p className="forgotpw" id="forgotLink">
                  <a href="/" onClick={handleBackToLogin}>
                    Back to Login
                  </a>
                </p>
              </Row>
            </Form>
          )}

          {otpValidated && !showOnlyBackToLogin && <div className="bms-admin bms-roleType">Enter new password</div>}
          {otpValidated && !errorMessage?.length && successMessage?.length ? <p className="login-box-msg green">{successMessage}</p> : null}
          {otpValidated && errorMessage?.length ? <p className="login-box-msg">{errorMessage}</p> : null}
          {otpValidated && (
            <Form className="loginForm resetPwd" noValidate validated={passwordFormHasError} onSubmit={handlePasswordReset}>
              {!showOnlyBackToLogin && (
                <div>
                  <Form.Group controlId="otp">
                    <Form.Control type="text" required placeholder="OTP" />
                    <Form.Control.Feedback type="invalid">Please enter OTP</Form.Control.Feedback>
                    <Row className="text-center">
                      <p className="forgotpw" id="forgotLink">
                        <a href="/" onClick={handleResendOtp}>
                          Resend OTP
                        </a>
                      </p>
                    </Row>
                  </Form.Group>

                  <div className="password-hint">
                    <p>Your Password Must Contain:</p>
                    <ol>
                      <li>At least 10 characters</li>
                      <li>At least an uppercase [A-Z] & a lowercase letter [a-z]</li>
                      <li>At least a digit [0-9]</li>
                      <li>At least 1 special character [!,@,#,$ etc]</li>
                    </ol>
                  </div>

                  <Form.Group controlId="newPassword">
                    <Form.Control type="password" required placeholder="New Password" />
                    <Form.Control.Feedback type="invalid">Please enter password</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="repeatNewPassword">
                    <Form.Control type="password" required placeholder="Confirm Password" isInvalid={errors?.length} />
                    <Form.Control.Feedback type="invalid">{errors || "Please enter password"}</Form.Control.Feedback>
                  </Form.Group>

                  <Button className="btn btn-block loginSubmit" type="submit" disabled={disableSubmit}>
                    UPDATE PASSWORD
                  </Button>
                </div>
              )}
              <Row className="text-center">
                <p className="forgotpw" id="forgotLink">
                  <a href="/" onClick={handleBackToLogin}>
                    Back to Login
                  </a>
                </p>
              </Row>
            </Form>
          )}
        </div>
      </div>
      {eventConfig?.copyrightText && <p className="copyright-text">{eventConfig.copyrightText}</p>}
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  updateFavicon: data => dispatch(AppDuc.creators.updateFavicon(data))
});

export default connect(
  createStructuredSelector({
    favicon: AppDuc?.selectors?.favicon
  }),
  mapDispatchToProps
)(ForgotPassword);
